import { readState } from "@/__main__/app-state.mjs";
import { IS_APP } from "@/__main__/constants.mjs";
import getData from "@/__main__/get-data.mjs";
import blitzMessage, { EVENTS } from "@/__main__/ipc-core.mjs";
import CoachingModel from "@/data-models/tft-coaching.mjs";
import TftLcuDataModel from "@/data-models/tft-lcu-data.mjs";
import TftRoundDataModel from "@/data-models/tft-round-data.mjs";
import * as API from "@/game-tft/api.mjs";
import { DataTftQueues, setPatches } from "@/game-tft/constants.mjs";
import { completeData, isCurrentMatch } from "@/game-tft/PostMatchCoaching.jsx";
import { devError } from "@/util/dev.mjs";

export default async function fetchCoachingData(params) {
  if (!IS_APP) return;
  const [, , matchId] = params;
  const patch = readState.tft.latestPatch?.patch;

  await blitzMessage(EVENTS.DB_READ, "TFT_ROUND_DATA")
    .then((stringifiedData) => {
      return getData(
        JSON.parse(stringifiedData),
        TftRoundDataModel,
        ["tft", "coaching", "roundData"],
        { skipSafetyCheck: true },
      );
    })
    .catch(devError);
  await blitzMessage(EVENTS.DB_READ, "TFT_LCU_DATA")
    .then((stringifiedData) => {
      return getData(
        JSON.parse(stringifiedData),
        TftLcuDataModel,
        ["tft", "coaching", "LcuData"],
        { skipSafetyCheck: true },
      );
    })
    .catch(devError);

  const roundData = readState.tft.coaching?.roundData;
  const postmatchData = readState.tft.coaching?.LcuData;

  const queueId =
    getLastRound(roundData)?.queueId || readState.settings?.lol?.lastQueueId;
  let mode =
    DataTftQueues.find(
      (q) => Number.parseInt(q.queueId) === Number.parseInt(queueId),
    )?.key || "RANKED";
  if (mode === "NORMAL") {
    mode = "RANKED";
  }

  if (
    (roundData || postmatchData) &&
    isCurrentMatch(matchId, roundData, postmatchData) &&
    completeData(roundData, postmatchData)
  ) {
    const queries = [
      { queryName: "unit_item_scores", cacheKeySuffix: "unitItemScores" },
      // { queryName: "augment_scores", cacheKeySuffix: "augmentScores" },
      // { queryName: "augment_duo_scores", cacheKeySuffix: "augmentDuoScores" },
      // {
      //   queryName: "augment_unit_scores",
      //   cacheKeySuffix: "augmentUnitScores",
      // },
      { queryName: "carry_unit_scores", cacheKeySuffix: "carryUnitScores" },
      {
        queryName: "synergy_unit_scores",
        cacheKeySuffix: "synergyUnitScores",
      },
      {
        queryName: "postmatch_score_stats",
        cacheKeySuffix: "postmatchScoreStats",
      },
    ];

    await Promise.all(
      queries.map(({ queryName, cacheKeySuffix }) =>
        fetchDataAndHandleError({ queryName, patch, mode }, [
          "tft",
          "coaching",
          `${cacheKeySuffix}_${patch}_${mode}`,
        ]),
      ),
    );
  }
}

async function fetchDataAndHandleError({ queryName, patch, mode }, cacheKey) {
  const fetchLastPatchData = async () => {
    const patches = Object.keys(setPatches);
    const prevPatch = patches[patches.indexOf(patch) - 1];
    if (setPatches[patch] === setPatches[prevPatch])
      await getData(
        API.getPostmatchCoachingScores({ queryName, patch: prevPatch, mode }),
        CoachingModel,
        cacheKey,
        { skipSafetyCheck: true },
      ).catch(devError);
  };

  try {
    await getData(
      API.getPostmatchCoachingScores({ queryName, patch, mode }),
      CoachingModel,
      cacheKey,
      { skipSafetyCheck: true },
    ).then((data) => {
      if (!data.data.executeDatabricksQuery.payload.result?.dataArray?.length) {
        fetchLastPatchData();
      }
    });
  } catch (e) {
    fetchLastPatchData();
  }
}

function getLastRound(roundsData) {
  if (!roundsData) return null;
  return roundsData?.[roundsData?.length - 1];
}
