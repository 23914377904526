import createModel, { Any, Optional, Silent } from "@/__main__/data-model.mjs";

const PlayerModel = createModel({
  allies: Optional([String]),
  alliesLength: Optional(Number),
  expCur: Optional(Number),
  expMax: Optional(Number),
  goldPassive: Optional(Number),
  goldInterest: Optional(Number),
  goldStreak: Optional(Number),
  health: Number,
  level: Number,
  winStreak: Number,
  room: Number,
  championsLength: Number,
  itemsLength: Number,
  champions: Silent([Any]),
  screenPos: {
    x: Number,
    y: Number,
  },
  gold: Optional(Number),
  summonerName: String,
  gameName: String,
  tagLine: String,
  items: Silent([String]),
  isPlayer: Boolean,
  rerolls: Optional(Number),
});

const RoundDataModel = createModel({
  roundStage: Number,
  roundIndex: Number,
  time: Number,
  roundOpponent: String,
  player: PlayerModel,
  opponents: Silent([PlayerModel]),
});

const GameDataModel = createModel(
  Silent([
    {
      game_id: Number,
      summonerName: String,
      gameName: String,
      tagLine: String,
      region: Optional(String),
      isSubscriber: Boolean,
      queueId: Number,
      leagues: Silent([Any]),
      patch: String,
      gameVersion: String,
      roundData: RoundDataModel,
    },
  ]),
);

export default GameDataModel;
