import createModel, { Optional, Silent } from "@/__main__/data-model.mjs";

const AugmentModel = createModel({
  icon: String,
  id: Number,
  name: String,
  nameId: String,
});

const TraitModel = createModel({
  id: String,
  name: String,
});

const ItemModel = createModel({
  icon: String,
  id: Number,
  name: String,
  nameId: String,
});

const UnitModel = createModel({
  icon: String,
  items: Silent([ItemModel]),
  level: Number,
  name: String,
  price: Number,
  traits: Silent([TraitModel]),
});

const CompanionModel = createModel({
  colorName: String,
  icon: String,
  speciesName: String,
});

const CustomAugmentContainerModel = createModel({
  description: String,
  displayName: String,
  iconPath: String,
  nameId: String,
});

const PlaybookModel = createModel({
  iconSmall: String,
  itemId: Number,
  name: String,
});

const LocalPlayerModel = createModel({
  augments: Silent([AugmentModel]),
  boardPieces: Silent([UnitModel]),
  companion: CompanionModel,
  customAugmentContainer: CustomAugmentContainerModel,
  ffaStanding: Number,
  health: Number,
  iconId: Number,
  isInteractable: Boolean,
  isLocalPlayer: Boolean,
  partnerGroupId: Number,
  playbook: PlaybookModel,
  puuid: String,
  rank: Number,
  riotIdGameName: String,
  riotIdTagLine: String,
  setCoreName: String,
  summonerId: Number,
  summonerName: String,
});

const PostmatchDataModel = createModel({
  gameId: Number,
  gameLength: Number,
  isRanked: Boolean,
  localPlayer: LocalPlayerModel,
  players: Silent([LocalPlayerModel]),
  queueId: Number,
  queueType: String,
  region: Optional(String),
  matchid: Optional(String),
});

export default PostmatchDataModel;
